import React, { useEffect, useState } from "react";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { auth } from "./firebase";
import Login from "./Login";
import Home from "./Home";
import Settings from "./Settings"
import Profile from "./Profile";
import Inbox from "./Inbox";
import BasicInfo from "./Settings/Basic";
import Account from "./Settings/Account";
import Messages from "./Settings/Messages";
import Signup from "./Signup";
import Username from "./Settings/Username";
import Forgot from "./Forgot";
import Sent from "./Sent";
import Invite from "./Invite";
import InvitePage from "./InvitePage";
import Threads from "./Threads";
import Conversation from "./Conversation";

function PrivateRoute() {

    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);
  
    useEffect(() => {
      auth.onAuthStateChanged((user) => {
        setCurrentUser(user)
        setPending(false)
      });
    }, []);

    if(pending){
        return <div className="pt-5 mt-5 text-center"><p>Loading...</p></div>
    }

    return currentUser ? <Outlet /> : <Navigate to="/" />;
};


function App() {

  return (
    <> 
        <BrowserRouter>
            <Routes>
              <Route path='/' element={<Home />}/>
              <Route path='/:username' element={<Profile />}/>
              <Route path='/invite/:id' element={<InvitePage />}/>
              <Route path="/login" element={<Login />}/>
              <Route path="/forgot" element={<Forgot />}/>
              <Route path="/signup" element={<Signup />}/>
              <Route path="/signup/:i" element={<Signup />}/>
              <Route path='/' element={<PrivateRoute/>}>
                <Route path="/inbox" element={<Inbox />}/>
                <Route path="/threads" element={<Threads />}/>
                <Route path="/threads/:id" element={<Conversation />}/>
                <Route path="/sent" element={<Sent />}/>
                <Route path="/settings" element={<Settings />}/>
                <Route path="/settings/username" element={<Username />}/>
                <Route path="/settings/basic" element={<BasicInfo />}/>
                <Route path="/settings/messages" element={<Messages />}/>
                <Route path="/settings/account" element={<Account />}/>
                <Route path="/settings/invite" element={<Invite />}/>
              </Route>
            </Routes>
        </BrowserRouter>
    </> 
  );
}

export default App;