import React, { useEffect, useState } from 'react';
import { useParams} from "react-router-dom";
import {
    db,
    doc,
    getDocs,
    addDoc,
    getBlob,
    deleteDoc,
    collection,
    where,
    query,
    limit, 
    ref,
    storage,
    uploadBytes,
    onSnapshot,
    serverTimestamp,
    auth,
    getMetadata
} from "./firebase";
import { DateTime } from "luxon";
import "./Profile.css"
import { useAuthState } from 'react-firebase-hooks/auth';
import { Offcanvas, Spinner } from 'react-bootstrap'
import Navbar from './Navbar';
import { Play, Pause } from 'react-bootstrap-icons';
import validator from 'validator'

function Profile() {

    useEffect(() => {
        document.title = "Profile"; 
    }, []);

    const { username } = useParams()
    const [ userActive ] = useAuthState(auth);
    const [ userProfile, setProfileInfo ] = useState([])
    const [ profileId, setProfileId ] = useState()
    const [ working, setWorking ] = useState(false);
    const [ following, setFollowing ] = useState(false);
    const [ friendshipId, setFriendshipId ] = useState(false);
    const [ active, setActive ] = useState(true)
    const [ blocked, setBlocked ] = useState(false)
    const [ name, setName ] = useState("");
    const [ profilePublic, setPublic ] = useState(true);
    const [ sending, setSending ] = useState(false);
    const [ photo, setPhoto ] = useState(false);

    ///////////////////////
    //Set up profile
    //////////////////////
    const fetchUser = async () => {

        if(!userActive){
            setActive(false);
        }

        const q = query(collection(db, "profiles"), where("username", "==", username.toLocaleLowerCase()), limit(1));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {

                setProfileInfo([doc.data()]);
                setProfileId(doc.id);
                if(doc.data().isActive === false ){
                    setActive(false);
                } else {
                    setActive(true);
                }
                if(doc.data().isPublic === false ){
                    setPublic(false);
                } else {
                    setPublic(true);
                }

                if(doc.data().photo){
                    setPhoto(true);
                } else {

                }

                if(doc.data().latestUrl){
                    document.getElementById(doc.id + '-audio').src = doc.data().latestUrl
                } else {
                    getBlob(ref(storage, doc.data().latest))
                    .then((theAudioURL) => {
                        document.getElementById(doc.id + '-audio').src = URL.createObjectURL(theAudioURL);
                    })
                    const audioRef = ref(storage, doc.data().latest);
                    getMetadata(audioRef)
                    .then((audioMetadata) => {
                        console.log('Audio ' + audioMetadata.cacheControl)
                    });
                }
        })


        if(userActive && profileId){
            console.log(profileId)
            const friend = query(collection(db, "friendships"), where("uid", "==", auth.currentUser.uid), where("following", "==", profileId), limit(1));
            const friendSnapshot = await getDocs(friend);
            friendSnapshot.forEach((doc) => {
                if (doc) {
                    console.log(true)
                    setFollowing(true);
                    setFriendshipId(doc.id);
                } else {
                    console.log(false)
                    setFollowing(false);
                }
                const source = friendSnapshot.metadata.fromCache ? "local cache" : "server";
                console.log("Friend data came from " + source);
            })

            const blocked = query(collection(db, "blocked"), where("uid", "==", profileId), where("blocked", "==", auth.currentUser.uid), limit(1));
            const blockedSnapshot = await getDocs(blocked);
            if(blockedSnapshot.empty){
                console.log("Blocked " + false)
                setBlocked(false);
            } else {
                console.log("Blocked " + true)
                setBlocked(true);
            }
        }

    }
    useEffect(() => {
        fetchUser();
    }, [profileId])


    //// VALIDATE EMAIL /////
    const [ email, setEmail ] = useState();
    const [ emailIsValid, setEmailIsValid ] = useState(false);
    //const [ emailError, setEmailError ] = useState('')
    const validateEmail = (e) => {
        var email = e.target.value
      
        if (validator.isEmail(email)) {
          //setEmailError('Valid Email :)')
          setEmail(email)
          setEmailIsValid(true)
          console.log("YEP")
        } else {
          //setEmailError('Enter valid Email!')
          console.log("NOPE")
          setEmailIsValid(false)
        }
    }


    ///////////////////////
    //Follow
    //////////////////////
    const follow = async (e) => {
        
        setWorking(true);
        if(following === false){
            const docRef = await addDoc(collection(db, "friendships"), {
                uid: auth.currentUser.uid,
                following: profileId,
                username: username.toLowerCase(),
                timestamp: serverTimestamp()
            })
            setFollowing(true);
            setFriendshipId(docRef.id);
            setWorking(false);
        } else {
            deleteFriendship(friendshipId);
            setFollowing(false);
            setFriendshipId();
            setWorking(false);
        }
    }
    async function deleteFriendship(id){
        await deleteDoc(doc(db, "friendships", id));
    }

    ///////////////////////
    //Playing Profile VM
    ///////////////////////
    async function addLog(){
        if(userActive){
            await addDoc(collection(db, "logs"), {
                uid: profileId,
                visitor: auth.currentUser.uid,
                where: "profile",
                type: 'listen',
                timestamp: serverTimestamp()
            })
        } else {
            await addDoc(collection(db, "logs"), {
                uid: profileId,
                visitor: "unknown",
                where: "profile",
                type: 'listen',
                timestamp: serverTimestamp()
            })
        }
    }
    const [profileVM, setProfileVM] = useState(false);
    function playProfileVM(id, audio) {

        if(!audio){
            console.log("No audio recorded yet")
            return;
        } else {
            setProfileVM(!profileVM)
            if(profileVM === true){
                document.getElementById(id + '-audio').pause(); 
            } else {
                document.getElementById(id + '-audio').play();
                addLog()
            }
            document.getElementById(id + '-audio').addEventListener('ended', () => setProfileVM(false));
            return () => {
                document.getElementById(id + '-audio').removeEventListener('ended', () => setProfileVM(false));
            };
        }
    }


    ///////////////////////
    //Recording Voicemail
    //////////////////////
    const [record, setRecord] = useState(false);
    const [review, setReview] = useState(true);
    const [send, setSend] = useState(true);
    const [showAudio, setShowAudio] = useState(false);
    function handleAudio() {
        setShowAudio(true)
    }

    //Listen for the offcanvas closing
    useEffect(() => {
        if (showAudio === false){
            setRecord(false);
            setReview(true);
            setSend(true);
            setName("");
        } 
    }, [showAudio]);


    ///START RECORDING
    var recorder, gumStream;
    const [fileURL, setFileURL] = useState();
    function toggleRecording() {

        setRecord(false);
        document.getElementById("counter").innerHTML = "&nbsp;"

        if (recorder && recorder.state === "recording") {
            setRecord(true);
            recorder.stop();
            gumStream.getAudioTracks()[0].stop();
        } else {
            navigator.mediaDevices.getUserMedia({audio: true, video: false})
            .then(function(stream) {

                gumStream = stream;
                var options = {
                type : 'audio/x-wav'
                }
                recorder = new MediaRecorder(stream,options);
                recorder.ondataavailable = function(e) {
                    clearInterval(downloadTimer);
                    var url = URL.createObjectURL(e.data);
                    setFileURL(e.data)
                    //document.getElementById("counter").innerHTML = "&nbsp;"
                    var recordedFile = document.getElementById('recordedVM')
                    recordedFile.src = url;
                    setRecord(true);
                    setReview(false);
                    setSend(false);
                    document.getElementById("recordButton").style.opacity = 0.1;
                    document.getElementById("reviewButton").style.opacity = 1;
                    document.getElementById("sendButton").style.opacity = 1;
                    document.getElementById("recordButton").textContent = "Record";
                };
                recorder.start()
                document.getElementById("recordButton").textContent = "Stop";
                document.getElementById("counter").style.opacity = 1;
                document.getElementById("recordTitle").textContent = "Press Stop when you're done recording";

                var timeleft = 30;
                var downloadTimer = setInterval(function(){
                if(timeleft <= 0){
                    if (recorder && recorder.state === "recording") {
                        recorder.stop();
                        gumStream.getAudioTracks()[0].stop();
                        
                    }
                    clearInterval(downloadTimer);
                    //document.getElementById("counter").innerHTML = "&nbsp;"
                }
                document.getElementById("counter").textContent = timeleft;
                timeleft -= 1;
                
                }, 1000);

            })
            .catch(function(err) {
                console.log('GUM failed with error, time diff: ');
            });
        }
        

    }

    ///////////////////////
    //Reviewing Voicemail
    //////////////////////
    const [isPlayingReview, setIsPlayingReview] = useState(false);
    function playRecordedAudio() { 
        setIsPlayingReview(!isPlayingReview)
        if(isPlayingReview === true){
            document.getElementById("recordedVM").pause(); 
        } else {
            document.getElementById("recordedVM").play()
        }
        document.getElementById("recordedVM").addEventListener('ended', () => setIsPlayingReview(false));
        return () => {
            document.getElementById("recordedVM").removeEventListener('ended', () => setIsPlayingReview(false));
        };
    } 

    /////////////////////
    //Upload Voicemail
    ////////////////////
    const sendVoicemail = ()=>{
  
        setSending(true);

        function uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
            });
        }
  
        let fileName = uuidv4();

        async function setDocument(from){
            await addDoc(collection(db, "tmp"), {
                status: 0,
                to: profileId,
                from: from,
                email: email,
                audio: 'users/' + profileId + '/voicemail/' + fileName + "_ts.wav",
                tmp: 'voicemail/' + fileName + ".wav",
                timestamp: serverTimestamp()
            })
        }

        document.getElementById('sendButton').disabled = true
        if(userActive){
            setDocument(auth.currentUser.uid)
            var metadata = {
                contentType: "audio/wav",
                customMetadata: {
                'public': 'true', 
                'owner' : auth.currentUser.uid,
                'to'    : profileId
                }
            };
        } else {
            setDocument(name)
            var metadata = {
                contentType: "audio/wav",
                customMetadata: {
                'public': 'true', 
                'owner' : name,
                'to'    : profileId,
                'email' : email
                }
            };
        }
  

        const storageRef = ref(storage, 'voicemail/' + fileName + ".wav");
        uploadBytes(storageRef, fileURL, metadata).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            alert("Your voicemail has been sent!")
            setSending(false);
            setShowAudio(false);
        }); // need catch
  
    }


    return (
        <div>
            <Navbar />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-10 col-xl-6 pt-4 pb-5 text-center" style={{marginBottom: `100px`}}>

                        {userProfile.length < 1? 
                        
                            <>Loading...</>
                        :
                        userProfile && userProfile.map(uInfo => 

                            <div key={profileId}>

                                <audio id={profileId + '-audio'}></audio>     

                                {uInfo.latest ?
                                
                                <svg height="150" width="150">
                                    <circle 
                                        onClick={(e) => playProfileVM(profileId, uInfo.latest)}
                                        className="circle" 
                                        cx="65" 
                                        cy="65" 
                                        r="63" 
                                        stroke="#59676e" 
                                        strokeWidth="4" 
                                        fillOpacity="0" 
                                    />
                                </svg>
                                : 
                                <></>
                                }
                                <img
                                    src={photo ? "https://firebasestorage.googleapis.com/v0/b/super-voicemail.appspot.com/o/users%2F" + profileId + "%2Fphotos%2F" + profileId + "_400x400.png?alt=media" : "apple-touch-icon-2.png"}
                                    id={profileId + "-image"}
                                    alt="Thumb"
                                    style={{width: `130px`, height: `130px`, overflow: `hidden`, objectFit: `cover`}} 
                                    className="border rounded-circle"
                                />
                                {uInfo.latest ?
                                <p style={{position: `relative`, left: `40px`, marginLeft: `auto`, marginRight: `auto`, padding: `0px`, width: `30px`, height: `30px`, borderRadius: `15px`, border: `solid 1px #eee`, textAlign: `left`, bottom: `25px`, backgroundColor: `#fff`}}>
                                    {profileVM ? 
                                        <Pause color="#333" style={{marginLeft: `2px`, marginTop: `1.5px`}}size={23}/> 
                                        : 
                                        <Play color="#ddd" style={{marginLeft: `3px`, marginTop: `1.5px`}} size={23}/> 
                                    }
                                </p>
                                :
                                <div className='py-3'>&nbsp;</div>
                                }
                             
                                <div className="row justify-content-between text-start mb-2">
                                    <div className="col">
                                        <h3 className='mb-0'><strong>{uInfo.displayName}</strong></h3>
                                        <span style={{fontSize: `0.7em`}}>
                                        {
                                        uInfo.joined ? 
                                            "Joined " + DateTime.fromJSDate(uInfo.joined && uInfo.joined.toDate()).toLocaleString({ month: 'long', year: 'numeric' })
                                            :
                                            ""
                                        }
                                        </span>
                                    </div>
                                    <div className='col-4 text-end'>
                                    {
                                    !userActive  ?
                                    <button type="button" className='btn bt-sm btn-outline-secondary' style={{fontSize: `0.8em`}} disabled >{following ? "Following" : "Follow"}</button>
                                    :
                                    <button type="button" className='btn bt-sm btn-outline-secondary' style={{fontSize: `0.8em`}} onClick={follow} disabled={working}>{following ? "Following" : "Follow"}</button>
                                    }
                                    </div>
                                </div>
                                <div className="row justify-content-between text-start mb-2">
                                    <p>{uInfo.bio}</p>
                                </div>

                                {blocked || active === false ?

                                    <>
                                        <button type="button" className="btn btn-default bg-danger text-white w-100 mt-3" style={{height: `50px`, opacity: `0.5`}} disabled={true}>This Mailbox Is Full</button>
                                        {/*
                                            <button type="button" className="btn btn-default btn-small bg-light mt-3" onClick={(e) => alert("Ok, we will let you know!")}>Notify me when this user has space</button>
                                        */}
                                    </>

                                    :

                                    <>

                                        {userActive ?
                                                <>
                                                <button type="button" className="btn btn-default bg-danger text-white w-100 mt-3" style={{height: `50px`}} onClick={(e) => handleAudio()} >Record Voicemail</button>
                                                <Offcanvas style={{backgroundColor: `#eee`, height: `350px`}} placement={'bottom'} show={showAudio} onHide={(e) => setShowAudio(false)}>
                                                    <Offcanvas.Header closeButton>
                                                    <Offcanvas.Title></Offcanvas.Title>
                                                    </Offcanvas.Header>
                                                    <Offcanvas.Body>
                                                        <div className="row m-0 p-0 justify-content-center">
                                                            <div className="col-12 col-md-5 text-center">
                                                            {!record ? 
                                                            <>
                                                            <strong id="recordTitle">Tap Record to begin</strong>
                                                            <h1 id="counter" className="m-0 py-2 text-center" style={{opacity: `0.05`}}>30</h1> 
                                                            </>

                                                            : 
                                                            
                                                            <>
                                                            <strong id="recordTitle">Review & Send your message</strong>
                                                            <h1 className="m-0 py-2 text-center">&nbsp;</h1>
                                                            </>
                                                            }
                                                            

                                                                {!sending ?
                                                                <div className="row mt-3">
                                                                    <div className="col-4">
                                                                        <button id="recordButton" className="btn btn-danger rounded-circle" style={{width: `80px`, height: `80px`}} onClick={(e) => toggleRecording()} disabled={record}>Record</button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button id="reviewButton" className="btn bg-primary rounded-circle text-white" style={{width: `80px`, height: `80px`, opacity: `0.1`}} onClick={(e) => playRecordedAudio()} disabled={review}>Review</button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button id="sendButton" className="btn bg-secondary rounded-circle text-white" style={{width: `80px`, height: `80px`, opacity: `0.1`}} onClick={sendVoicemail} disabled={send}>Send</button>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <Spinner animation="border" />
                                                                }
                                                                
                                                                {!record ?

                                                                <p style={{fontSize: `0.6em`, marginTop: `20px`}}>Tip: Make sure you've given your browser microphone access</p>  
                                                                
                                                                :

                                                                <p style={{fontSize: `0.6em`, marginTop: `20px`}}>Tip: Close this window to cancel your message and start over</p>  

                                                                }
                                                            </div>
                                                            <audio id="recordedVM"></audio>
                                                            
                                                        </div>
                                                    </Offcanvas.Body>
                                                </Offcanvas>
                                                </>

                                            :

                                            profilePublic  ?
                                            <>

                                                <button type="button" className="btn btn-default bg-danger text-white w-100 mt-3" style={{height: `50px`}} onClick={(e) => handleAudio()} >Record Voicemail</button>
                                                <Offcanvas style={{backgroundColor: `#eee`, height: `450px`}} placement={'bottom'} show={showAudio} onHide={(e) => setShowAudio(false)}>
                                                    <Offcanvas.Header closeButton>
                                                    <Offcanvas.Title></Offcanvas.Title>
                                                    </Offcanvas.Header>
                                                    <Offcanvas.Body>
                                                        <div className="row m-0 p-0 justify-content-center">
                                                            <div className="col-12 col-md-5 text-center">

                                                            {name.length > 1 && emailIsValid === true ?

                                                                !record ? 
                                                                <>
                                                                <strong id="recordTitle">Tap Record to begin</strong>
                                                                <h1 id="counter" className="m-0 py-2 text-center" style={{opacity: `0.05`}}>30</h1> 
                                                                </>

                                                                : 
                                                                
                                                                <>
                                                                <strong id="recordTitle">Review & Send your message</strong>
                                                                <h1 className="m-0 py-2 text-center">&nbsp;</h1>
                                                                </>

                                                                :

                                                                <>
                                                                <strong id="recordTitle"></strong>
                                                                <h1 id="counter" className="m-0 py-2 text-center">Start your message</h1> 
                                                                <p style={{fontSize: `0.7em`}}>Your name and email are required to leave a message for {uInfo.displayName}</p>
                                                                </>

                                                            }
                                                                
                                                                <div className="row mb-3 mt-2">
                                                                    <input id="name" type="text" className='form-control p-2 w-100' name="name" maxLength="35" autoComplete="none" value={name} onChange={(e) => setName(e.target.value)} placeholder="Your name" disabled={record}/>
                                                                </div>
                                                                <div className="row mb-5">
                                                                    <input id="email" type="text" className='form-control p-2 w-100' name="email" autoComplete="none" onChange={(e) => validateEmail(e)} placeholder="Email address" disabled={record}/>
                                                                </div>

                                                                {!sending ?
                                                                <div className="row">
                                                                    <div className="col-4">
                                                                        {name.length > 1 && emailIsValid === true ?
                                                                        <button id="recordButton" className="btn btn-danger rounded-circle" style={{width: `80px`, height: `80px`}} onClick={(e) => toggleRecording()} disabled={record}>Record</button>
                                                                            :
                                                                        <button id="recordButton" className="btn btn-danger rounded-circle" style={{width: `80px`, height: `80px`, opacity: `0.2`}} onClick={(e) => toggleRecording()} disabled={true}>Record</button>
                                                                        }
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button id="reviewButton" className="btn bg-primary rounded-circle text-white" style={{width: `80px`, height: `80px`, opacity: `0.2`}} onClick={(e) => playRecordedAudio()} disabled={review}>Review</button>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <button id="sendButton" className="btn bg-secondary rounded-circle text-white" style={{width: `80px`, height: `80px`, opacity: `0.2`}} onClick={sendVoicemail} disabled={send}>Send</button>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <Spinner animation="border" />
                                                                }

                                                            </div>
                                                            <audio id="recordedVM"></audio>
                                                            
                                                        </div>
                                                    </Offcanvas.Body>
                                                </Offcanvas>
                                                </>
 
                                            :
                                                <>
                                                <button type="button" className="btn btn-default bg-danger text-white w-100 mt-3" style={{height: `50px`, opacity: `0.5`}} onClick={(e) => alert("You need to be signed in to do that.")} >Record</button>
                                                </>

                                            }
                                            </>
                                        
                                       }
                                   
                                    {uInfo.phone ? <div  style={{marginTop: `20px`}}><a href={'tel:' + uInfo.phone} className="text-decoration-none text-secondary small">Or call me @ <u>805-399-9123</u></a></div> : ""}
                        
                            </div>
                        )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile