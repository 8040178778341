import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from "react-router-dom";
import { auth, getDocs, collection, db, query, where, orderBy, getStorage, limit, getDownloadURL, doc, updateDoc, ref} from "./firebase";
import Navbar from './Navbar';
import NavbarSide from './NavbarSide';
import { DateTime } from "luxon";
import "./Inbox.css"
import Filter from 'bad-words';
import {ChevronRight} from 'react-bootstrap-icons'
import Switch from '@mui/material/Switch';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


function Conversation() {

    const storage = getStorage();
    const { id } = useParams()

    var badWords = new Filter();
    const [ profanityFilter, setProfanityFilter ] = useState(false);

    const [ mobile, setMobile ] = useState();
    function getWindowDimensions() {
        const { innerWidth: width } = window;
        return {
          width
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        document.title = "Inbox"; 
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [name, setName] = useState();

    const fetchUser = async () => {

        const q = query(collection(db, "voicemail"), where("toId", "==", auth.currentUser.uid), where("owner", "==", id), orderBy("timestamp", "desc"), limit(20));
        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach((doc) => {
            if(!doc.data().audioUrl){
                setAudio(doc.id, doc.data().audio)
            }
            results.push({id: doc.id, data: doc.data()}) 
        })
        setInboxResults(results)
        setLoading(false);
    }

    const [loading, setLoading] = useState(true);
    const [ inboxResults, setInboxResults ] = useState([])
    useEffect(() => {
        fetchUser()
    }, [])


    async function updateAudioUrl(id, theURL){
        const cacheAudio = doc(db, 'voicemail', id);
        await updateDoc(cacheAudio, {
            audioUrl: theURL
        })
        .then(() => {
        });
    }
    async function setAudio(id, audio) { 
        getDownloadURL(ref(storage, audio))
        .then((theURL) => {
            updateAudioUrl(id, theURL)
        })
    }



    const getSafety = (score) => {
        if (score === '0') {
            return <span className='text-success'><strong>Safe</strong></span>
        } else if (score === '1') {
            return <span className='text-warning'><strong>May be unsafe</strong></span>
        } else if (score === '2') {
            return <span className='text-danger'><strong>Nsfw</strong></span>
        }
        return <span className='text-black'><strong>Unknown</strong></span>
    }

    const getFeeling = (feeling) => {
        if (feeling.length > 2) {
            return <span className='text-black'> | {feeling}</span>
        }
        return " "
    }


    const [showAudio, setShowAudio] = useState(false);
    function handleAudio() {
        setShowAudio(true)
    }


  return (
        <div>

        <div className="d-md-none d-block">
            <Navbar/>
        </div>  

        <div className='container-fluid '>
        <div className="row">
            <NavbarSide/>       

            <div className='col'>

            { loading ? (
                <div className="d-lg-none pt-2" style={{position: "relative", left: "44%", top: "40%"}} >
                    Loading...
                </div>
            ) : (
 
                    <div className="col-12 pt-0 pb-5">

                        {inboxResults && inboxResults.map(voicemail => 
                        <>
                            <ul className="list-group list-group-flush border-bottom p-0 m-0" key={voicemail.id} >
                                <li className="list-group-item list-group-item-action p-0 m-0 py-3" data-bs-toggle="offcanvas" data-bs-target={`#offcanvasTop-${voicemail.id}`} aria-controls="offcanvasTop" onClick={(e) => handleAudio()}>
                                    <div className='row'>
                                        <div className='col-10'>
                                            <div style={{float: `left`, height: `35px`}}>
                                                <img 
                                                    src={voicemail.data.fromPhoto ? "https://firebasestorage.googleapis.com/v0/b/super-voicemail.appspot.com/o/users%2F" + voicemail.data.owner + "%2Fphotos%2F" + voicemail.data.owner + "_400x400.png?alt=media" : "apple-touch-icon-2.png"} 
                                                    id={voicemail.id + '-image'} 
                                                    alt="profile"
                                                    className="rounded-circle border" 
                                                    style={{width: `38px`, height: `38px`, overflow: `hidden`, objectFit: `cover`}} 
                                                />
                                            </div>
                                        
                                            <div style={{float: `left`, marginLeft: `10px`, height: `40px`}}>
                                                <p className='mb-0 pt-0'>
                                                    {voicemail.data.fromUsername
                                                    ?
                                                    <Link to={`/` + voicemail.data.fromUsername} className='text-black text-decoration-none'>{voicemail.data.fromName}</Link>
                                                    :
                                                    <>{voicemail.data.fromName}</>
                                                    }
                                                </p>
                                                <p style={{fontSize: `0.6em`}}>
                                                    {DateTime.fromJSDate(voicemail.data.timestamp && voicemail.data.timestamp.toDate()).toRelative()}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-2' style={{textAlign: `right`, paddingTop:`7px`, paddingRight: `25px`}}>
                                            <ChevronRight />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        
                                            <div className="row small">
                                                <div >
                                                <p className='m-0' style={ voicemail.data.safety === '2' && profanityFilter === false ? ({filter: 'blur(1px) opacity(50%)', fontSize: `0.9em`}) : voicemail.data.transcription ? {color:'#333', fontSize: `0.9em`} : { color: '#aaa', fontSize: `0.9em`} }>{!profanityFilter ? (voicemail.data.transcription ? badWords.clean(voicemail.data.transcription) : 'No transcription available...') : voicemail.data.transcription ? voicemail.data.transcription : 'No transcription available...' }</p>
                                                </div>
                                            </div>
                                            <div className="row small mt-2 ">
                                                <p className='col-auto m-0' style={{color:'#555', fontSize: `0.7em`}}>
                                                    
                                                    {getSafety(voicemail.data.safety)} | {voicemail.data.duration ? 
                                                        voicemail.data.duration.toFixed(0).length < 2 ? "00:0" + voicemail.data.duration.toFixed(0) : "00:" + voicemail.data.duration.toFixed(0)
                                                        : 
                                                        ""
                                                    }
                                                    
                                                    {voicemail.data.feeling ? getFeeling(voicemail.data.feeling) : " "}
                                                        
                                                </p>
                                            </div>
                                    </div>
                                </li>
                            </ul>
                            <div className={windowDimensions.width < 786 ? 'offcanvas offcanvas-bottom' : 'offcanvas offcanvas-end'}  style={windowDimensions.width < 786 ? {height: `65%`} : {height: `100%`}} tabIndex="-1" id={`offcanvasTop-${voicemail.id}`} aria-labelledby="offcanvasTopLabel">
                                <ConversationModal 
                                    id={voicemail.id}
                                    name={voicemail.data.fromName} 
                                    img={voicemail.data.fromPhoto ? "https://firebasestorage.googleapis.com/v0/b/super-voicemail.appspot.com/o/users%2F" + voicemail.data.owner + "%2Fphotos%2F" + voicemail.data.owner + "_400x400.png?alt=media" : "apple-touch-icon-2.png"}
                                    timestamp={DateTime.fromJSDate(voicemail.data.timestamp && voicemail.data.timestamp.toDate()).toRelative()}
                                    audio={voicemail.data.audioUrl}
                                    transcript={voicemail.data.transcription}
                                    duration={voicemail.data.duration.toFixed(0).length < 2 ? "0" + voicemail.data.duration.toFixed(0) : voicemail.data.duration.toFixed(0)}
                                    showAudio={showAudio}
                                />
                            </div>
                        </>
                        )}
                    </div>

            )}
            </div>
            </div>
            </div>
        </div>
  )
}

export default Conversation;




function ConversationModal(props) {

    //NEED TO LISTEN FOR THE MODAL TO CLOSE TO STOP THE AUDIO

    const TinyText = styled(Typography)({
        fontSize: '0.75rem',
        opacity: 0.38,
        fontWeight: 500,
        letterSpacing: 0.2,
      });

    const duration = props.duration;
    const id = props.id;
    const [paused, setPaused] = useState(true);
    function formatDuration(value) {
        const minute = Math.floor(value / 60);
        const secondLeft = value - minute * 60;
        return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
    }
    
    const [isPlaying, setIsPlaying] = useState(false);

    async function playAudio() { 

        setPaused(!paused)
        setIsPlaying(!isPlaying)

        if(paused === true){
            document.getElementById('playAudio-' + id).src = props.audio;
            document.getElementById('playAudio-' + id).play()
            setIsPlaying(false)
            startTimer();
        } else {
            document.getElementById('playAudio-' + id).pause()
            setIsPlaying(true)
            clearInterval(intervalRef.current);
        }

        document.getElementById('playAudio-' + id).addEventListener('ended', () => {
            setPaused(true);
            setIsPlaying(false)
        })
        return () => {
            document.getElementById('playAudio-' + id).removeEventListener('ended', () => {
            setPaused(true);
            setIsPlaying(false)
            })
        };
        
    }

    const intervalRef = useRef();
    const [trackProgress, setTrackProgress] = useState(0);
    const startTimer = () => {
        // Clear any timers already running
        clearInterval(intervalRef.current);
  
        intervalRef.current = setInterval(() => {
            setTrackProgress(document.getElementById('playAudio-' + id).currentTime);
        }, [100]);
      }

    const onScrub = (value) => {
        // Clear any timers already running
      clearInterval(intervalRef.current);
      document.getElementById('playAudio-' + id).currentTime = value;
      setTrackProgress(document.getElementById('playAudio-' + id).currentTime);
    }

    const onScrubEnd = () => {
        // If not already playing, start
        if (!isPlaying) {
          playAudio()
        }
        startTimer();
    }

    return (
        <div key={id}>

            <div className="offcanvas-header">
                <div className='col-10 mt-1'>
                    <div style={{float: `left`, height: `35px`}}>
                    <img 
                        src={props.img} 
                        alt="profile"
                        className="rounded-circle border" 
                        style={{width: `40px`, height: `40px`, overflow: `hidden`, objectFit: `cover`}} 
                    />
                    </div>
                    <div style={{float: `left`, marginLeft: `10px`, height: `40px`}}>
                        <p className="d-inline">{props.name}</p>
                        <p style={{fontSize: `0.6em`}}>{props.timestamp}</p>
                    </div>
                </div>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <p className='m-0 mb-5 px-2' style={props.transcript ? {color:'#333', fontSize: `0.9em`} : { color: '#aaa', fontSize: `0.9em`} }> {!props.transcript ? 'No transcription available...' : props.transcript }</p>
                
                <div style={{position: `absolute`, bottom: `30px`, width: `92%`, paddingBottom: `30px`}} >
                <audio id={`playAudio-${id}`}/>
                <Slider
                aria-label="time-indicator"
                size="medium"
                value={trackProgress}
                min={0}
                step={0.1}
                max={`${props.duration}`}
                onChange={(e) => onScrub(e.target.value)}
                onMouseUp={onScrubEnd}
                onKeyUp={onScrubEnd}
                style={{color:`#333`}}
                />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: -1.5, }}>
                <TinyText>{formatDuration(trackProgress.toFixed(0))}</TinyText>
                <TinyText>-{formatDuration(duration - trackProgress.toFixed(0))}</TinyText>
            </Box>
        <div className='text-center mt-4'>
                <IconButton
                aria-label={paused ? 'play' : 'pause'}
                onClick={() => playAudio()}
                >
                {paused ? (
                <PlayArrowRounded
                    sx={{ fontSize: '3rem' }}
                    htmlColor={`#444`}
                />
                ) : (
                <PauseRounded sx={{ fontSize: '3rem' }} htmlColor={`#444`} />
                )}
            </IconButton>
            </div>
                </div>
            </div>
        </div>
    )
}