import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from '../Navbar';
import NavbarSide from '../NavbarSide';
import { auth } from "../firebase";
import { useAuthState } from 'react-firebase-hooks/auth';

function Account() {

    const [ user ] = useAuthState(auth);

    function SignOut(props) {
        return auth.currentUser && (
            <Link to="/" className="nav-link link-white text-white" onClick={() => auth.signOut()}>Sign Out</Link>
        )
    }

  return (
  <div>
        <div className="d-md-none d-block">
            <Navbar/>
        </div>  

        <div className="row">

            <NavbarSide/>     
                <div className='ps-3 pe-4 col'>
            <div className="col-12 mt-4 pt-0 pb-4 text-center">
                <button type="submit" className="btn btn-secondary w-100 mt-5 p-2"><SignOut email={user.email} /></button>
            </div>
        </div>
        </div>
  </div>
  )
}

export default Account;
