import React from 'react'
import { Link, NavLink} from "react-router-dom";
import { auth } from "./firebase";
import { useAuthState } from 'react-firebase-hooks/auth';

function Navbar() {

    const [user] = useAuthState(auth);

    return (
        <>
            {user ? 
            <>
            <div className="alert-primary py-2 text-center small" role="alert">
                Get the 0.2 beta iOS app <a href="https://testflight.apple.com/join/ntpUxtf6" className="alert-link">click here to download</a>
            </div>
            </>
            :
            <></>
            }
            <div className="navbar navbar-light navbar-expand-md bg-white border-bottom">
                <div className="container-fluid col-12 py-1">
                    {user ? 
                    <Link to="/inbox" className="navbar-brand ms-0 ms-md-3"><img src="/apple-touch-icon.png" alt="logo" height="35" /><p className='d-none d-md-inline ms-2 small'>HousePhone</p></Link>
                    :              
                    <Link to="/" className="navbar-brand ms-0 ms-md-3"><img src="/apple-touch-icon.png" alt="logo" height="35" /><p className='d-none d-md-inline ms-2 small'>HousePhone</p></Link>
                    }
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon link-dark"></span>
                    </button>
                    <div className="collapse navbar-collapse mx-sm-2" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto">
                        {user ? 
                        <>
                        <li className="nav-item me-3">
                        <NavLink to="/inbox" className="nav-link link-black">Inbox</NavLink>
                        </li>
                        <li className="nav-item me-3">
                        <NavLink to="/sent" className="nav-link link-black">Sent</NavLink>
                        </li>
                        <li className="nav-item me-3">
                        <NavLink to="/settings" className="nav-link link-black">Settings</NavLink>
                        </li>
                        <li className="nav-item me-3">
                        </li>
                        </>
                        :
                        <>
                        <li className="nav-item me-3">
                        <NavLink to="/login" className="nav-link link-black">Login</NavLink>
                        </li>
                        </>
                        }
                        </ul>
                    </div>
                </div>
            </div> 
        </>   
    )
}



export default Navbar