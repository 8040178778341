import React, { useEffect, useState } from 'react';
import { Link, useParams} from "react-router-dom";
import {
    db,
    doc,
    getDoc,
    getBlob,
    ref,
    storage,
    auth
} from "./firebase";
import { DateTime } from "luxon";
import { useAuthState } from 'react-firebase-hooks/auth';
import "./Profile.css"
import Navbar from './Navbar';
import { Play, Pause } from 'react-bootstrap-icons';

function InvitePage() {

    useEffect(() => {
        document.title = "Invited"; 
    }, []);

    const { id } = useParams();
    const [ userActive ] = useAuthState(auth);
    const [ loading, setLoading ] = useState(true);
    const [ userProfile, setProfileInfo ] = useState([]);
    const [ profileId, setProfileId ] = useState();
    const [ displayName, setDisplayName ] = useState();
    const [ username, setUsername ] = useState();
    const [ joined, setJoined ] = useState();
    const [ audio, setAudio ] = useState();
    const [ code, setCode ] = useState();


    ///////////////////////
    //Set up page
    //////////////////////
    async function getPhoto(id){
        const docRef = doc(db, "profiles", id);
        const docSnap = await getDoc(docRef);

        setDisplayName(docSnap.data().displayName);
        setJoined(docSnap.data().joined);
        if (docSnap.exists()) {
            if(docSnap.data().photo){
                getBlob(ref(storage, docSnap.data().photo))
                .then((theURL) => {
                    document.getElementById(id + '-image').src = URL.createObjectURL(theURL)
                })
            }
        } else {
            console.log("No such document!");
        }
    }


    const fetchUser = async () => {
        const docRef = doc(db, "invites", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log(docSnap.data())
            setProfileInfo(docSnap.data());
            setProfileId(docSnap.data().uid); //USER ID
            setUsername(docSnap.data().username); //USER ID
            setCode(docSnap.data().code); //INVITE CODE
            setAudio(docSnap.data().latest); //AUDIO FILE
            getPhoto(docSnap.data().uid);
            setLoading(false);
        } else {
            setLoading(false);
            console.log("No such document!");
        }
    }
    useEffect(() => {
        fetchUser();
    }, [])

    



    ///////////////////////
    //Playing Profile VM
    ///////////////////////
    const [profileVM, setProfileVM] = useState(false);
    function playProfileVM(id, audio) {
        if(!audio){
            console.log("No audio recorded yet")
            return;
        } else {
            setProfileVM(!profileVM)
            if(!document.getElementById(id + '-audio').src){
                getBlob(ref(storage, audio))
                .then((theURL) => {
                    document.getElementById(id + '-audio').src = URL.createObjectURL(theURL);
                })
            }
            if(profileVM === true){
                document.getElementById(id + '-audio').pause(); 
            } else {
                document.getElementById(id + '-audio').play();
            }
            document.getElementById(id + '-audio').addEventListener('ended', () => setProfileVM(false));
            return () => {
                document.getElementById(id + '-audio').removeEventListener('ended', () => setProfileVM(false));
            };
        }
    }


    return (
        <div>
            <Navbar />
            { loading ? (
                <div className="pt-5 text-center">
                    Loading...
                </div>
            ) : (

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 pt-4 pb-5 text-center" style={{marginBottom: `100px`}}>


                       <audio id={profileId + '-audio'}></audio>     

                        <svg height="150" width="150">
                            <circle 
                                onClick={(e) => playProfileVM(profileId, audio)}
                                className="circle" 
                                cx="65" 
                                cy="65" 
                                r="63" 
                                stroke="#59676e" 
                                strokeWidth="4" 
                                fillOpacity="0" 
                            />
                        </svg>

                        <img
                            src={"../vm.png"}
                            id={profileId + "-image"}
                            alt="Thumb"
                            style={{width: `130px`, height: `130px`, overflow: `hidden`, objectFit: `cover`}} 
                            className="border rounded-circle"
                        />

                        <p style={{position: `relative`, left: `30px`, marginLeft: `auto`, marginRight: `auto`, padding: `0px`, width: `30px`, height: `30px`, borderRadius: `15px`, border: `solid 1px #eee`, textAlign: `left`, bottom: `20px`, backgroundColor: `#fff`}}>
                            {profileVM ? 
                                <Pause color="#333" style={{marginLeft: `2px`, marginTop: `1.5px`}} size={23}/> 
                                : 
                                <Play color="#ddd" style={{marginLeft: `3px`, marginTop: `1.5px`}} size={23}/> 
                            }
                        </p>

                        <p className='mb-0'><Link to={`/` + username} className='text-black text-decoration-none'><strong>{displayName}</strong></Link> has invited you to Housephone.</p>
                        <p className='mb-5'>Click the button below to join for free and save this message.</p>

                        {userActive ?
                            <p>Save</p>
                        :
                            <Link to={`/signup/` + code} className='btn btn-secondary text-decoration-none'>Join with Invite</Link>
                        }
                
                    </div>
                </div>
            </div>
            )}
        </div>
    )
}

export default InvitePage