import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sendResetEmail } from "./firebase";

function Forgot() {

    const [email, setEmail] = useState("");
    const history = useNavigate();
      
    const handleReset = async (e) => {
        e.preventDefault();
        setEmail("");
        sendResetEmail(email);
        //history("/login");
      }

    return (
        <div>
            <div className="container pt-md-5 pt-2 col-12 col-lg-6 mt-5 pt-5 pb-5">
                <form onSubmit={handleReset}>
                    <div className="form-floating mb-4">
                    <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" required/>
                    <label htmlFor="email" className="text-secondary">Email</label>
                    </div>
                    <button type="submit" className="btn btn-dark login-btn">Send Reset Email</button>
                </form>
            </div>
        </div>
    )
}

export default Forgot;
