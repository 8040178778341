import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import {
    db,
    doc,
    updateDoc,
    collection,
    getDocs,
    getDoc,
    query,
    ref,
    where,
    limit,
    storage,
    uploadBytes,
    auth,
    httpsCallable, 
    getFunctions
} from "./firebase";
import Offcanvas from 'react-bootstrap/Offcanvas'
import Navbar from './Navbar';

function Invite() {

    const functions = getFunctions();
    const [loading, setLoading] = useState(true);
    const [code, setCode] = useState();
    const [invitePageId, setInvitePageId] = useState();
    const [invitePageCreated, setInvitePageCreated] = useState(false)

    useEffect(() => {
        document.title = "Invites"; 
    }, []);


    ///////////////////////
    //Set up profile
    //////////////////////
    const fetchUser = async () => {

        const q = query(collection(db, "invites"), where("uid", "==", auth.currentUser.uid), where("active", "==", true), limit(1));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setCode(doc.data().code);
            setInvitePageId(doc.id);
            if(doc.data().latest){
                setInvitePageCreated(`housephone.app/invite/${doc.id}`)
            }
        })
    }
    useEffect(() => {
        fetchUser();
    }, [code])


    ///COPY BUTTON//
    function copy(text) {
        return window.navigator.clipboard.writeText(text);
    }

    ///////////////////////
    //Recording Profile Voicemail
    //////////////////////
    const [record, setRecord] = useState(false);
    const [review, setReview] = useState(true);
    const [send, setSend] = useState(true);
    const [showAudio, setShowAudio] = useState(false);
    function handleAudio() {
        setShowAudio(true)
    }
    //Listen for the offcanvas closing
    useEffect(() => {
        if (showAudio === false){
            setRecord(false);
            setReview(true);
            setSend(true);
        } 
    }, [showAudio]);


    var recorder, gumStream;
    const [fileURL, setFileURL] = useState();

    function toggleRecording() {

        document.getElementById("counter").innerHTML = "&nbsp;"

        if (recorder && recorder.state == "recording") {
            recorder.stop();
            gumStream.getAudioTracks()[0].stop();
        } else {
            navigator.mediaDevices.getUserMedia({audio: true, video: false})
            .then(function(stream) {

                gumStream = stream;
                recorder = new MediaRecorder(stream);
                recorder.ondataavailable = function(e) {
                    clearInterval(downloadTimer);
                    var url = URL.createObjectURL(e.data);
                    setFileURL(e.data)
                    document.getElementById("counter").innerHTML = "&nbsp;"
                    var recordedFile = document.getElementById('recordedVM')
                    recordedFile.src = url;
                    setRecord(true);
                    setReview(false);
                    setSend(false);
                    document.getElementById("recordButton").style.opacity = 0.2;
                    document.getElementById("reviewButton").style.opacity = 1;
                    document.getElementById("sendButton").style.opacity = 1;
                    document.getElementById("recordButton").textContent = "Record";
                };
                recorder.start()

                var timeleft = 45;
                var downloadTimer = setInterval(function(){
                if(timeleft <= 0){
                    if (recorder && recorder.state == "recording") {
                    recorder.stop();
                    gumStream.getAudioTracks()[0].stop();
                    }
                    clearInterval(downloadTimer);
                    document.getElementById("counter").innerHTML = "&nbsp;"
                }
                document.getElementById("counter").textContent = timeleft;
                timeleft -= 1;
                
                }, 1000);

            });
        }
    }


    ///////////////////////
    //Reviewing Voicemail
    //////////////////////
    const [isPlayingReview, setIsPlayingReview] = useState(false);
    function playRecordedAudio() { 
        setIsPlayingReview(!isPlayingReview)

        if(isPlayingReview === true){
            document.getElementById("recordedVM").pause(); 
        } else {
            document.getElementById("recordedVM").play()
        }
        document.getElementById("recordedVM").addEventListener('ended', () => setIsPlayingReview(false));
        return () => {
            document.getElementById("recordedVM").removeEventListener('ended', () => setIsPlayingReview(false));
        };
    } 


    /////////////////////
    //Upload Voicemail
    ////////////////////
    const sendVoicemail = ()=>{
  
        var metadata = {
            cacheControl: 'public,max-age=500',
            customMetadata: {
              'public': 'true',
              'owner' : auth.currentUser.uid
            }
        };
  
        function uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
            });
        }
  
        let fileName = uuidv4();
        const storageRef = ref(storage, 'invites/' + fileName);
        uploadBytes(storageRef, fileURL, metadata).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            
            async function update(){
                const inviteRef = doc(db, "invites", invitePageId);
                await updateDoc(inviteRef, {
                    latest: 'invites/' + fileName + '_ts.wav',
                    hasVoicemail: true
                });
            }
            update()
            setShowAudio(false)
            setInvitePageCreated(`housephone.app/invite/${invitePageId}`)
        });
  
    }
    

    const createInviteButton = async () => {

        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);
        var username;
        var displayName;
        if (docSnap.exists()) {
            username = docSnap.data().username;
            displayName = docSnap.data().displayName;
        }

        const createInvite = httpsCallable(functions, 'createInvite');
        createInvite({ username: username, displayName: displayName })
            .then((result) => {

                const data = result.data;
                console.log("return " + result.data);
                setCode(result.data);
            })
            .catch((error) => {
                console.log("Error Info: " + error)
                const code = error.code;
                const message = error.message;
                const details = error.details;
        });
    };


    return (
    <div>
        <Navbar />

        <div key={auth.currentUser.uid + "-container"} className="container">
            <div key={auth.currentUser.uid + "-row"} className="row justify-content-center">
                <div key={auth.currentUser.uid + "-item"} className="col-12 col-lg-10 col-xl-8 mt-4 pt-0 pb-5 text-center" >

            {code ? 

                invitePageCreated ? 
                
                    invitePageCreated

                :
                
                <>
                <h3>Your have an invite!</h3>
                {code}
                <p className='mt-5'>Record a message to invite someone. Then we'll create a custom invite page with your message.</p>

                <div className='row text-center mt-5'>
                    {
                    //<div className='col-6'>
                    //    <button type="button" className="btn btn-default bg-secondary text-white w-100" onClick={(e) => copy(code)}>Copy Invite Code</button>
                    //</div>
                    }
                    <div className='col'>
                        <button type="button" className="btn btn-default bg-danger text-white w-100" onClick={(e) => handleAudio()}>Create Housephone Invite</button>
                    </div>
                </div>

                <Offcanvas style={{backgroundColor: `#eee`, height: `350px`}} placement={'bottom'} show={showAudio} onHide={(e) => setShowAudio(false)}>
                    <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="row m-0 p-0 justify-content-center">
                            <div className="col-12 col-md-5 text-center">
                            <strong>Record a message for the person you are inviting.</strong>
                                <p style={{fontSize: `0.7em`}}>After you upload your message you will be taken to your custom invite page.</p>
                                <div className="row">
                                    <div className="col-4">
                                        <button id="recordButton" className="btn btn-danger rounded-circle" style={{width: `80px`, height: `80px`}} onClick={(e) => toggleRecording()} disabled={record}>Record</button>
                                    </div>
                                    <div className="col-4">
                                        <button id="reviewButton" className="btn bg-primary rounded-circle text-white" style={{width: `80px`, height: `80px`, opacity: `0.2`}} onClick={(e) => playRecordedAudio()} disabled={review}>Review</button>
                                    </div>
                                    <div className="col-4">
                                        <button id="sendButton" className="btn bg-secondary rounded-circle text-white" style={{width: `80px`, height: `80px`, opacity: `0.2`}} onClick={sendVoicemail} disabled={send}>Upload</button>
                                    </div>
                                </div>
                            </div>
                            <audio id="recordedVM"></audio>
                            <h4 id="counter" className="m-0 py-2 text-center">&nbsp;</h4>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
                </>
                : 
                <button className='btn' onClick={(e) => createInviteButton()}>Create Invite</button>

            }

                </div>
            </div>
        </div>


    </div>
    )
}

export default Invite;