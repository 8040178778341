import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { auth, getDocs, collection, db, query, where, orderBy, limit, getBlob, ref, storage } from "./firebase";
import { DateTime } from "luxon";
import Navbar from './Navbar'
import NavbarSide from './NavbarSide'


export default function Sent() {

    const [loading, setLoading] = useState(true);
    const [ inboxResults, setInboxResults ] = useState([])

    useEffect(() => {
        document.title = "Inbox"; 
        setLoading(false);
    }, []);


    async function getSent(){
        const q = query(collection(db, "voicemail"), where("owner", "==", auth.currentUser.uid), orderBy("timestamp", "desc"), limit(20));
        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach((doc) => {
            results.push({id: doc.id, data: doc.data()}) 
        })

        console.log(results)
        setInboxResults(results)
    }

    useEffect(() => {

        getSent()

        /*
        const q = query(collection(db, "voicemail"), where("owner", "==", auth.currentUser.uid), orderBy("timestamp", "desc"), limit(20));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const results = [];
        querySnapshot.forEach((doc) => {
            results.push({id: doc.id, data: doc.data()}) 
        })
        console.log(results)
        setInboxResults(results)
        })
        */

    }, [])

    const [playing, setPlaying] = useState(false);
    function playAudio(id, url) { 
        setPlaying(!playing);
        if(!document.getElementById(id + '-audio').src){
            getBlob(ref(storage, url))
            .then((theURL) => {
                document.getElementById(id + '-audio').src = URL.createObjectURL(theURL)
            })
        }
        if(playing === true){
            document.getElementById(id + '-audio').pause()
            document.getElementById(id + '-button').textContent = "Play";
        } else {
            document.getElementById(id + '-audio').play(); 
            document.getElementById(id + '-button').textContent = "Pause";
        }
        document.getElementById(id + '-audio').addEventListener('ended', () => document.getElementById(id + '-button').textContent = "Play");
        return () => {
            document.getElementById(id + '-audio').removeEventListener('ended', () => setPlaying(false));
        };
    }


    return (
    <>
        <div className="d-md-none d-block">
            <Navbar/>
        </div>  

        <div className='container-fluid '>
            <div className="row">
                <NavbarSide/>       

                    <div className='col'>
                        {inboxResults && inboxResults.map(voicemail => 
                            <div className="py-3 border-bottom" key={voicemail.id}>
                                <div className="row">
                                    <div className="col-9 col-md-10">
                                        <p className='mb-0 pt-0'><Link to={`/` + voicemail.data.toUsername} className='text-black text-decoration-none'>{voicemail.data.toName}</Link></p>
                                        <p className='mb-0 pt-0' style={{fontSize: `0.6em`}}>
                                            Sent: {DateTime.fromJSDate(voicemail.data.timestamp && voicemail.data.timestamp.toDate()).toRelative()}
                                        </p>
                                    </div>
                                    <div className='col-3 col-md-2 text-end'>
                                        <audio id={voicemail.id + '-audio'} type="audio/wav"></audio>
                                        <button id={voicemail.id + '-button'} onClick={(e) => playAudio(voicemail.id, voicemail.data.audio)} className='btn btn-sm rounded' style={ voicemail.data.played === true ? {backgroundColor:'#fff', border: `solid 1px #ddd`} : {backgroundColor: `#0198E1`, border: `0px`, color: `#FFF`}}>Play</button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <p style={{fontSize: `0.6em`}}>{voicemail.data.transcription}</p>
                                    <p className='mb-0 pt-0' style={{fontSize: `0.6em`}}>{voicemail.data.played ? "Listened" : ""}</p>
                                </div>
                            </div>
                        )}
                    </div>
            </div>
        </div>
    </>
    )
}
