import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { auth, getDocs, collection, db, query, orderBy, limit} from "./firebase";
import Navbar from './Navbar';
import NavbarSide from './NavbarSide';
import { DateTime } from "luxon";
import "./Inbox.css"
import Filter from 'bad-words';
import {ChevronRight} from 'react-bootstrap-icons'
import Switch from '@mui/material/Switch';


function Inbox() {

    var badWords = new Filter();
    const [ profanityFilter, setProfanityFilter ] = useState(false);

    useEffect(() => {
        document.title = "Inbox"; 
    }, []);

    const fetchUser = async () => {

        const q = query(collection(db, "users/" + auth.currentUser.uid + "/threads"), orderBy("timestamp", "desc"), limit(10));
        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach((doc) => {
            results.push({id: doc.id, data: doc.data()}) 
        })
        setInboxResults(results)
        setLoading(false);
    }

    const [loading, setLoading] = useState(true);
    const [ inboxResults, setInboxResults ] = useState([])
    useEffect(() => {
        fetchUser()
    }, [])

    const getSafety = (score) => {
        if (score === '0') {
            return <span className='text-success'><strong>Safe</strong></span>
        } else if (score === '1') {
            return <span className='text-warning'><strong>May be unsafe</strong></span>
        } else if (score === '2') {
            return <span className='text-danger'><strong>Nsfw</strong></span>
        }
        return <span className='text-black'><strong>Unknown</strong></span>
    }


  return (
        <div>

        <div className="d-md-none d-block">
            <Navbar/>
        </div>  

        <div className='container-fluid '>
        <div className="row">
            <NavbarSide/>       

            <div className='col'>

            { loading ? (
                <div className="d-lg-none pt-2" style={{position: "relative", left: "44%", top: "40%"}} >
                    Loading...
                </div>
            ) : (
 
                    <div className="col-12 pt-0 pb-5">

                        <div className="row justify-content-between py-2 pb-4">
                            <div className='col-6 text-secondary text-start pt-3' style={{fontSize: `1em`}}>
                                <p className="m-0 fw-bold">Messages</p>
                            </div>
                            <div className='col-6 text-end pt-2'>
                                <label forhtml='filterProfanity' className='p-0' style={{fontSize: `0.7em`}}>Allow profanity</label>
                                <Switch defaultChecked={profanityFilter} style={{float: `right`}} id="filterProfanity"  onClick={() => setProfanityFilter((profanityFilter) => !profanityFilter)} />
                            </div>
                        </div>

                        {inboxResults && inboxResults.map(voicemail => 
                        <>
                            <Link to={`${voicemail.id}`} className='text-black text-decoration-none' key={voicemail.id}>
                            <ul className="list-group list-group-flush border-top p-0 m-0" >
                                <li className="list-group-item list-group-item-action p-0 m-0 py-3" >
                                    <div className='row'>
                                        <div className='col-10'>
                                            <div style={{float: `left`, height: `35px`}}>
                                                <img 
                                                    src={voicemail.data.fromPhoto === true ? "https://firebasestorage.googleapis.com/v0/b/super-voicemail.appspot.com/o/users%2F" + voicemail.id + "%2Fphotos%2F" + voicemail.id + "_400x400.png?alt=media" : "apple-touch-icon-2.png"} 
                                                    id={voicemail.id + '-image'} 
                                                    alt="profile"
                                                    className="rounded-circle border" 
                                                    style={{width: `38px`, height: `38px`, overflow: `hidden`, objectFit: `cover`}} 
                                                />
                                            </div>
                                        
                                            <div style={{float: `left`, marginLeft: `10px`, height: `40px`}}>
                                                <p className='mb-0 pt-0'>
                                                    {voicemail.data.fromName}
                                                </p>
                                                <p style={{fontSize: `0.6em`}}>
                                                    {DateTime.fromJSDate(voicemail.data.timestamp && voicemail.data.timestamp.toDate()).toRelative()}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-2' style={{textAlign: `right`, paddingTop:`7px`, paddingRight: `25px`}}>
                                            <ChevronRight />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="row small">
                                            <p className='m-0' style={ voicemail.data.safety === '2' && profanityFilter === false ? ({filter: 'blur(1px) opacity(50%)', fontSize: `0.9em`}) : voicemail.data.transcription ? {color:'#333', fontSize: `0.9em`} : { color: '#aaa', fontSize: `0.9em`} }>{!profanityFilter ? (voicemail.data.transcription ? badWords.clean(voicemail.data.transcription) : 'No transcription available...') : voicemail.data.transcription ? voicemail.data.transcription : 'No transcription available...' }</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            </Link>
                        </>
                        )}
                    </div>

            )}
            </div>
            </div>
            </div>
        </div>
  )
}

export default Inbox;
