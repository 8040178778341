import React from 'react'
import { Link, NavLink} from "react-router-dom";

function NavbarSide() {

    return (
        <>
            <div className='border-end col d-none d-md-block pe-0 p-0' style={{flex: `0 0 200px`, minHeight: `100vh`}}>

            <Link to="/inbox" className="navbar-brand ps-4"><img src="/apple-touch-icon.png" alt="logo" height="30" className='mt-4 mb-4' /><p className='d-inline ps-2 text-secondary fw-light m-0 p-0' style={{fontSize: `0.7em`}}>HousePhone</p></Link>

            <ul className="list-group list-group-flush border-top">
                <li className="list-group-item list-group-item-action p-0 py-3 ps-2">
                    <NavLink to="/inbox" className="nav-link text-black">
                        Inbox
                    </NavLink>
                </li>
                <li className="list-group-item list-group-item-action p-0 py-3 ps-2">
                    <NavLink to="/sent" className="nav-link text-black">
                        Sent
                    </NavLink>
                </li>
                <li className="list-group-item list-group-item-action p-0 py-3 ps-2">
                    <NavLink to="/settings" className="nav-link text-black">
                        Settings
                    </NavLink>
                </li>
            </ul>

            </div>

        </>
    )
}

export default NavbarSide