import { initializeApp } from 'firebase/app'
import { getAuth, isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged,  RecaptchaVerifier, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendSignInLinkToEmail, sendPasswordResetEmail } from 'firebase/auth'
import { getFirestore, onSnapshot, updateDoc, increment, collection, query, doc, where, getDocs, getDoc, limit, orderBy, loadBundle, namedQuery, addDoc, setDoc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, uploadBytes, getDownloadURL, getBlob, getMetadata, deleteObject } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
//import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

export const firebaseApp = initializeApp({
  apiKey: "AIzaSyB7j8PcHISBHTN5XDac0qMs8LZO143XGeg",
  authDomain: "super-voicemail.firebaseapp.com",
  projectId: "super-voicemail",
  storageBucket: "super-voicemail.appspot.com",
  messagingSenderId: "155995638051",
  appId: "1:155995638051:web:e14fa41b4209ae946ba496",
  measurementId: "G-YTF2TVGFYB"
});

const auth = getAuth();
const db = getFirestore(firebaseApp);
const functions = getFunctions();
const analytics = getAnalytics();
const storage = getStorage();

/*
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6Lf5QaIeAAAAADBw4a_2elekrl5Yow_n2h5tlTOB'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
*/

//const cache = enableIndexedDbPersistence(db)

/*
enableIndexedDbPersistence(db)
.catch((err) => {
if (err.code == 'failed-precondition') {
// Multiple tabs open, persistence can only be enabled
// in one tab at a a time.
// ...
} else if (err.code == 'unimplemented') {
// The current browser does not support all of the
// features required to enable persistence
// ...
}
});
*/

const signIn = async (email, password) => {

  signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    const user = userCredential.user;
  })
  .catch((err) => {
    if(err.code === 'auth/invalid-email'){
      alert("Invalid email or password");
    } else if (err.code === 'auth/wrong-password'){
      alert("Invalid password")
    } else {
      alert(err.code)
    }
  })

};

const register = async (name, email, password, code, invitedBy) => {

  createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      displayName: name,
      email: email,
      invited: {code: code, uid: invitedBy},
      joined: serverTimestamp(),
    })
  })
  .catch((error) => {
    alert(error)
  });
  
};

const sendResetEmail = async (email) => {
  sendPasswordResetEmail(auth, email)
  .then(() => {
      alert("Please check your inbox.")
  })
  .catch((error) => {
    alert(error.message)
  });
};
const logout = () => {
  auth.signOut();
};  

export {
  getAuth,
  auth,
  analytics,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  isSignInWithEmailLink, 
  signInWithEmailLink,
  RecaptchaVerifier,
  httpsCallable,
  getFunctions,
  functions,
  db,
  storage,
  increment,
  ref,
  setDoc,
  getDownloadURL,
  getMetadata,
  getStorage,
  getBlob, 
  uploadBytes,
  addDoc,
  getDocs,
  updateDoc,
  onSnapshot,
  deleteDoc,
  deleteObject,
  serverTimestamp,
  getDoc,
  loadBundle,
  namedQuery,
  doc,
  collection,
  where,
  query,
  limit,
  orderBy,
  signIn,
  register,
  sendResetEmail,
  logout,
};
