import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { auth, register, httpsCallable, getFunctions } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Navbar from "./Navbar";

function Signup() {

    const functions = getFunctions();
    const { i } = useParams()


    //INVITE WORK
    const [ invite, setInvite ] = useState(false);
    const [ code, setCode ] = useState("");
    const [ invitedBy, setInvitedBy ] = useState("");
    const [ invalid, setInvalid ] = useState("");
    const [ isLoading, setIsLoading ] = useState(false);
    function checkInvite(e) {
        e.preventDefault();
        setIsLoading(true);
        console.log(code);
        const getInvite = httpsCallable(functions, 'invite');
        getInvite({ code: code })
            .then((result) => {

                const data = result.data;
                console.log(result)
                setIsLoading(false);
                if(data.length == 0){
                    setInvite(false)
                    setInvalid("I'm sorry, that code is invalid.")
                    setCode("")
                } else {
                    setInvitedBy(data[0].uid)
                    setInvite(true)
                }
                
            })
            .catch((error) => {
                console.log(error)
                const code = error.code;
                const message = error.message;
                const details = error.details;
        });
    }


    useEffect(() => {
        if(i){
            const code = i;
            setIsLoading(true);
            setCode(code)
            const getInvite = httpsCallable(functions, 'invite');
            getInvite({ code: code })
                .then((result) => {
    
                    const data = result.data;
                    console.log(result)
                    setIsLoading(false);
                    if(data.length == 0){
                        setInvite(false)
                        setInvalid("I'm sorry, that code is invalid.")
                        setCode("")
                    } else {
                        setInvitedBy(data[0].uid)
                        setInvite(true)
                    }
                    
                })
                .catch((error) => {
                    console.log(error)
                    const code = error.code;
                    const message = error.message;
                    const details = error.details;
            });
        }
    }, [])



    //SIGNUP WORK
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading] = useAuthState(auth);
    const history = useNavigate();
  
    const handleSignUp = async (e) => {
      e.preventDefault();
      register(name, email, password, code, invitedBy);
    }

    useEffect(() => {
        if (loading) {
          // maybe trigger a loading screen
          return;
        }
        if (user) history("/settings/username");
    }, [user, loading]);





    return (
        <>
        <Navbar />
            <div className="container col-11 col-lg-6  mt-5 pt-5">
                <div className="justify-content-center">

                    {invite == false ?

                    <div className="text-center">
                        {isLoading ?
                        <p className="mt-3">Checking your invite code...</p>
                        :
                        <>
                            <h3>Housephone is currently in private beta</h3>
                            <p>If you received an invite code, you can enter it below to begin the registration process.</p>
                            
                            <form onSubmit={checkInvite}>
                                <input type="text" className="form-control py-3" placeholder="Invite Code" value={code} onChange={(e) => setCode(e.target.value)} required/>
                                <button type="submit" className="btn btn-dark btn-lg btn-block mt-2 w-100" disabled={!code}>Verify Invite Code</button>
                            </form>
                            
                            <p style={{color: `red`}} className="mt-3">{invalid}</p>
                        </>
                        }
                    </div>
                    
                    :

                    <form onSubmit={handleSignUp}>
                        <h3 className="text-center">Sign up for Housephone</h3> 
                        <div className="form-floating mb-4 mt-4">
                        <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full name" required/>
                        <label htmlFor="name">Full name</label>
                        </div>
                        <div className="form-floating mb-4">
                        <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email address" required/>
                        <label htmlFor="email">Email address</label>
                        </div>
                        <div className="form-floating mb-4">
                        <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" required/>
                        <label htmlFor="password">Password</label>
                        </div>
                        <button type="submit" className="btn btn-dark btn-lg btn-block w-100" disabled={!password}>Sign Up</button>
                    </form>

                    }

                </div>
            </div>
        </>
    )
}

export default Signup