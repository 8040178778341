import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    db,
    doc,
    updateDoc,
    onSnapshot,
    ref,
    getBlob,
    storage,
    uploadBytes,
    auth,
    getDownloadURL
} from "./firebase";
import { useAuthState } from 'react-firebase-hooks/auth';
import Offcanvas from 'react-bootstrap/Offcanvas'
import 'react-phone-number-input/style.css'
import { PencilFill } from 'react-bootstrap-icons';
import Navbar from "./Navbar";
import NavbarSide from "./NavbarSide";
import './Settings.css'

export default function Settings() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        document.title = "Settings"; 
    }, []);


    const [ user ] = useAuthState(auth);
    const [ userInfo, setUserInfo ] = useState()
    const [ image , setImage ] = useState();

    useEffect(() => {
        const unsub = onSnapshot(doc(db, "users", auth.currentUser.uid), (doc) => {
            setUserInfo(doc.data());
            console.log(doc.data())
            if(doc.data().photo){
                getBlob(ref(storage, doc.data().photo))
                .then((theURL) => {
                    document.getElementById(auth.currentUser.uid + '-image').src = URL.createObjectURL(theURL)
                })
            }
            if(!doc.data().latestUrl){
                setAudio(doc.id, doc.data().latest)
            }

        });

        if(userInfo){
            setLoading(false);
            console.log("info " + userInfo.username)
            unsub();
        }

    }, [userInfo])

    async function updateAudioUrl(id, theURL){
        const cacheAudio = doc(db, 'profiles', id);
        await updateDoc(cacheAudio, {
            latestUrl: theURL
        })
    }
    async function setAudio(id, audio) { 
        getDownloadURL(ref(storage, audio))
        .then((theURL) => {
            document.getElementById(id + '-audio').src = theURL
            updateAudioUrl(id, theURL)
        })
    }



    ///////////////////////
    //Playing Profile VM
    ///////////////////////
    const [profileVM, setProfileVM] = useState(false);
    function playProfileVM(id, audio) {

        if(!audio){
            console.log("No audio recorded yet")
            return;
        } else {
            setProfileVM(!profileVM)
            if(!document.getElementById(id + '-audio').src){
                getBlob(ref(storage, audio))
                .then((theURL) => {
                    document.getElementById(id + '-audio').src = URL.createObjectURL(theURL);
                })
            }
            if(profileVM === true){
                document.getElementById(id + '-audio').pause(); 
            } else {
                document.getElementById(id + '-audio').play();
            }
            document.getElementById(id + '-audio').addEventListener('ended', () => setProfileVM(false));
            return () => {
                document.getElementById(id + '-audio').removeEventListener('ended', () => setProfileVM(false));
            };
        }

    }


    ///////////////////////
    //Recording Profile Voicemail
    //////////////////////
    const [record, setRecord] = useState(false);
    const [review, setReview] = useState(true);
    const [send, setSend] = useState(true);
    const [showAudio, setShowAudio] = useState(false);
    function handleAudio() {
        setShowAudio(true)
    }
    //Listen for the offcanvas closing
    useEffect(() => {
        if (showAudio === false){
            setRecord(false);
            setReview(true);
            setSend(true);
        } 
    }, [showAudio]);


    var recorder, gumStream;
    const [fileURL, setFileURL] = useState();

    function toggleRecording() {

      document.getElementById("counter").innerHTML = "&nbsp;"

      if (user) {

        if (recorder && recorder.state === "recording") {
          recorder.stop();
          gumStream.getAudioTracks()[0].stop();
        } else {
            navigator.mediaDevices.getUserMedia({audio: true, video: false})
            .then(function(stream) {

                gumStream = stream;
                recorder = new MediaRecorder(stream);
                recorder.ondataavailable = function(e) {
                    clearInterval(downloadTimer);
                    var url = URL.createObjectURL(e.data);
                    setFileURL(e.data)
                    document.getElementById("counter").innerHTML = "&nbsp;"
                    var recordedFile = document.getElementById('recordedVM')
                    recordedFile.src = url;
                    setRecord(true);
                    setReview(false);
                    setSend(false);
                    document.getElementById("recordButton").style.opacity = 0.2;
                    document.getElementById("reviewButton").style.opacity = 1;
                    document.getElementById("sendButton").style.opacity = 1;
                    document.getElementById("recordButton").textContent = "Record";
                };
                recorder.start()

                var timeleft = 20;
                var downloadTimer = setInterval(function(){
                if(timeleft <= 0){
                  if (recorder && recorder.state === "recording") {
                    recorder.stop();
                    gumStream.getAudioTracks()[0].stop();
                  }
                  clearInterval(downloadTimer);
                  document.getElementById("counter").innerHTML = "&nbsp;"
                }
                document.getElementById("counter").textContent = timeleft;
                timeleft -= 1;
                
                }, 1000);

            });
        }

      } else {
        console.log("No user")
      }
    }


    ///////////////////////
    //Reviewing Voicemail
    //////////////////////
    const [isPlayingReview, setIsPlayingReview] = useState(false);
    function playRecordedAudio() { 
        setIsPlayingReview(!isPlayingReview)

        if(isPlayingReview === true){
            document.getElementById("recordedVM").pause(); 
        } else {
            document.getElementById("recordedVM").play()
        }
        document.getElementById("recordedVM").addEventListener('ended', () => setIsPlayingReview(false));
        return () => {
            document.getElementById("recordedVM").removeEventListener('ended', () => setIsPlayingReview(false));
        };
    } 


    /////////////////////
    //Upload Voicemail
    ////////////////////
    const sendVoicemail = ()=>{
  
        var metadata = {
            cacheControl: 'public,max-age=100',
            customMetadata: {
              'public': 'true',
              'owner' : auth.currentUser.uid
            }
        };
  
        function uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
            });
        }
  
        let fileName = uuidv4();
        const storageRef = ref(storage, 'users/' + auth.currentUser.uid + '/machine/' + fileName);
        uploadBytes(storageRef, fileURL, metadata).then((snapshot) => {

            async function update(){
                const profileVMRef = doc(db, "profiles", auth.currentUser.uid);
                await updateDoc(profileVMRef, {
                    latest: 'users/' + auth.currentUser.uid + '/machine/' + fileName + '_ts.wav',
                    latestUrl: null
                });
                alert("Your mailbox has been updated.");
            }
            update()
            setShowAudio(false)

        });
  
    }

    const onIconClick = () => {
        const input = document.getElementById('upload');
        if (input) {
           input.click();
        }
    };

    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          setImage(e.target.files[0]);
          document.getElementById(auth.currentUser.uid + '-image').src = URL.createObjectURL(e.target.files[0])
          document.getElementById('savePhoto').hidden = false;
        }
    };

    async function updatePhoto() {
        const userPhoto = doc(db, "users", auth.currentUser.uid);
        await updateDoc(userPhoto, {
            photo: "/users/" + auth.currentUser.uid + "/photos/" + auth.currentUser.uid + "_400x400.png"
        });
        const profilePhoto = doc(db, "profiles", auth.currentUser.uid);
        await updateDoc(profilePhoto, {
            photo: "/users/" + auth.currentUser.uid + "/photos/" + auth.currentUser.uid + "_400x400.png"
        });
        alert("Your photo has been updated.");
    }

    const upload = ()=>{
        if(image == null)
        return;
        const storageRef = ref(storage, `/users/${auth.currentUser.uid}/photos/${auth.currentUser.uid}.png`);
        const metadata = {
            cacheControl: 'public,max-age=50',
            customMetadata: {
                public: true
            }
        }
        uploadBytes(storageRef, image, metadata).then((snapshot) => {
            console.log('Uploaded a blob or file!');
            document.getElementById('savePhoto').hidden = true;
        });
        updatePhoto()
    }

    return (
        <div>

        <div className="d-md-none d-block">
            <Navbar/>
        </div>  

        <div className='container-fluid'>
        <div className="row">
            <NavbarSide/>       

            <div className='col'>

            { loading ? (
                <div className="d-lg-none pt-2">
                    Loading...
                </div>
            ) : (
 
                <div className='ps-3 pe-4 col'>
                <div className="row p-0">
                    <div key={auth.currentUser.uid + "-item"} className="col-12 mt-4 pt-0 pb-5 text-center" >

                        <img 
                            src={"vm.png"}
                            id={auth.currentUser.uid + "-image"}
                            alt="Profile Photo" 
                            style={{width: `150px`, height: `150px`, overflow: `hidden`, objectFit: `cover`, cursor: `pointer`}} 
                            className="border rounded-circle mb-2" 
                            onClick={onIconClick} 
                        />
                        <p style={{fontSize: `0.7em`}}>Click the photo to change</p>

                        <div className="row justify-content-center">
                            <input className="form-control" type="file" id="upload" accept="image/png, image/jpeg" onChange={imageChange} hidden />
                            <button type="submit" id="savePhoto" className="btn btn-dark text-white mt-3 mb-4 py-2 text-secondary w-75" onClick={upload} hidden>Save Photo</button>
                            <audio id={auth.currentUser.uid + '-audio'} type="audio/wav"></audio>    
                            
                            <button type="submit" className="btn btn-secondary mt-4 py-2 ms-3" style={{width: `30%`, fontSize: `.9em`}} onClick={(e) => playProfileVM(auth.currentUser.uid, userInfo.latest)} disabled={!userInfo.latest}>{profileVM ? 'Pause' : 'Current Message' }</button>
                            <button type="submit" className="btn btn-danger mt-4 py-2 ms-3" style={{width: `30%`, fontSize: `.9em`}} onClick={(e) => handleAudio()}>Record Message</button>
                        </div>
                        <div className="row mt-4">
                            <div className="d-inline">
                                <Link to={`/${userInfo.username}`} style={{fontSize: `.9em`, marginTop: `20px`}}>www.housephone.app/{userInfo.username}</Link>
                                <Link to='username' className="ms-3 p-0 m-0"><PencilFill color="gray" size={16} className="mt-1" /></Link>
                            </div>
                       </div>
                        <div className="row" >
                            <Link to="basic" className="py-3 mt-4 text-start border-bottom text-decoration-none text-secondary">Basic Info</Link>
                        </div>
                        <div className="row">
                            <Link to="messages" className="py-3 text-start border-bottom text-decoration-none text-secondary">Mailbox Settings</Link>
                        </div>
                        <div className="row">
                            <Link to="account" className="py-3 text-start border-bottom text-decoration-none text-secondary">Account Settings</Link>
                        </div>


                        <Offcanvas style={{backgroundColor: `#eee`, height: `350px`}} placement={'bottom'} show={showAudio} onHide={(e) => setShowAudio(false)}>
                            <Offcanvas.Header closeButton>
                            <Offcanvas.Title></Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className="row m-0 p-0 justify-content-center">
                                    <div className="col-12 col-md-5 text-center">
                                    <strong>Record your profile message</strong>
                                        <div className="row mt-5">
                                            <div className="col-4">
                                                <button id="recordButton" className="btn btn-danger rounded-circle" style={{width: `80px`, height: `80px`}} onClick={(e) => toggleRecording()} >Record</button>
                                            </div>
                                            <div className="col-4">
                                                <button id="reviewButton" className="btn bg-primary rounded-circle text-white" style={{width: `80px`, height: `80px`, opacity: `0.2`}} onClick={(e) => playRecordedAudio()} disabled={review}>Review</button>
                                            </div>
                                            <div className="col-4">
                                                <button id="sendButton" className="btn bg-secondary rounded-circle text-white" style={{width: `80px`, height: `80px`, opacity: `0.2`}} onClick={sendVoicemail} disabled={send}>Send</button>
                                            </div>
                                        </div>
                                    </div>
                                    <audio id="recordedVM"></audio>
                                    <h4 id="counter" className="m-0 py-2 text-center">&nbsp;</h4>
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                </div>
            </div>
            )}
            </div>
        </div>
        </div>
        </div>
    )
}