import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    db,
    doc,
    updateDoc,
    getDocs,
    query,
    collection,
    onSnapshot,
    where,
    limit,
    auth
} from "../firebase";
import { useAuthState } from 'react-firebase-hooks/auth';
import Navbar from '../Navbar';
import NavbarSide from '../NavbarSide';


function Username() {

    const [loading, setLoading] = useState(true);
    const [user] = useAuthState(auth);
    const [username, setUsername] = useState();
    const route = useNavigate();

    useEffect(() => {
        const unsub = onSnapshot(doc(db, "users", auth.currentUser.uid), (doc) => {
            setUsername(doc.data().username);
            console.log(doc.data())
        });

        if(username){
            unsub();
        }

    }, [username])

    useEffect(() => {
        console.log(username)
        setLoading(false);
    }, [username])


    async function handleChange(e){

        var usernameInput = e.target.value.toLowerCase()

        document.getElementById("verify").textContent = " ";

        if(usernameInput.indexOf(' ') >= 0 || usernameInput.length < 3){
            console.log("Invalid")
            document.getElementById("verify").className = "bg-white text-danger";
            document.getElementById("verify").textContent = "Invalid Username";
            document.getElementById("update").disabled = true;
            return;
        } else {
            console.log("Valid")
            const username = query(collection(db, "profiles"), where("username", "==", usernameInput), limit(1));
            const usernameSnapshot = await getDocs(username);
            if (!usernameSnapshot.empty) {
                console.log("taken")
                document.getElementById("verify").className = "bg-white text-danger";
                document.getElementById("verify").textContent = "Username not available";
                document.getElementById("update").disabled = true;
            } else {
                console.log("Available")
                document.getElementById("verify").className = "bg-white text-success";
                document.getElementById("verify").textContent = "Available";
                document.getElementById("update").disabled = false;
                setUsername(usernameInput)
            }
        }
    }

    //////////////////////
    //Update User Info
    /////////////////////
    
    function handleSubmit(e) {
        e.preventDefault();
        updateUsername()
    }
    async function updateUsername(){
        const usersUsernameRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(usersUsernameRef, {
            username: username
        });
        const profileUsernameRef = doc(db, "profiles", auth.currentUser.uid);
        await updateDoc(profileUsernameRef, {
            username: username
        });
        alert("Update complete");
        route("/about")
    }

    return (
    <div>
        <div className="d-md-none d-block">
            <Navbar/>
        </div>  

        <div className="row">

            <NavbarSide/>    
    
        { loading ? (
                <div className="d-lg-none pt-2">
                    Loading...
                </div>
        ) : (

            <div className='ps-3 pe-4 col'>
                <div className="row p-0">
                <div className="col-12 mt-4 pt-0 pb-4">

                {username 
                ?

                    <form id="updateUsername" onSubmit={handleSubmit}>
                        <div className="container-fluid">
                            <div className="row">
                                <h3 className="text-center mt-4">Change your username</h3>
                                <small className="text-center pb-3">We give you a generic one when you join</small>
                                <div className="col-12 pb-2">
                                    <div className="mb-2">
                                        <input id="username" type="text" defaultValue={username} onChange={handleChange} className="form-control py-2" minLength="3" maxLength="20" pattern="^\S+$" style={{textTransform: `lowercase`}} required />
                                    </div>
                                    <p className="py-2" id="verify" style={{height: `16px`}}>{' '}</p>
                                    Requirements:
                                    <ul>
                                        <li><small><small>Must be more than 3 characters</small></small></li>
                                        <li><small><small>Must be less than 20 characters</small></small></li>
                                        <li><small><small>May not contain spaces</small></small></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <button id="update" type="submit" className="btn btn-primary">Update</button>
                            </div>
                        </div>   
                    </form>
                
                : 

                <p className="text-center">Loading...</p>

                }
                </div>
            </div>
        </div>
        )}
    </div>
    </div>
    )
}

export default Username;