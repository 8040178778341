import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, signIn } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./App.css";
import Navbar from "./Navbar";

function Login() {

  useEffect(() => {
    document.title = "Login";  
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading] = useAuthState(auth);
  const history = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();
    signIn(email, password);
  }

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) history("/inbox");
  }, [user, loading]);
  
  return (
    <div>
      <Navbar />
      <div className="container col-11 col-lg-6 mt-5 pt-5 pb-5">
        <div className="justify-content-center text-center">
            <h3>Log in</h3>
            <form onSubmit={handleSignIn}>
              <div className="form-floating mb-4 mt-5">
                <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address"/>
                <label htmlFor="email">Email</label>
              </div>
              <div className="form-floating mb-4">
                <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password"/>
                <label htmlFor="password">Password</label>
              </div>
                <button type="submit" className="btn btn-dark btn-lg btn-block w-100">Login</button>
            </form>
            <div className="row mt-3">
              <Link to="/forgot" className="text-decoration-none text-black small mt-1">Forgot password?</Link>
            </div>
        </div>
      </div>
    </div>
  );
}
export default Login;

