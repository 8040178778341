import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function Home() {

    const [user, loading] = useAuthState(auth);
    const history = useNavigate();

    useEffect(() => {
        document.title = "Home"; 
        if (user) history("/inbox"); 
    }, [user, loading]);

    return (
        <div>
            <div className="container">
                <div className="row justify-content-center mb-1">
                    <div className="col-12 col-lg-9 col-xl-7 mt-5 pt-5 pb-5 px-3 text-center">
                        <img className="mt-5" src="apple-touch-icon.png" width={80} />
                        <h1 className="mt-3 mb-2">Welcome to HousePhone.</h1>
                        <p className="mt-1">We're currently in private beta.<br/> Have an account? <Link to="/login">Login</Link></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home