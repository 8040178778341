import { useState, useEffect } from "react";
import { Link, useNavigate  } from "react-router-dom";
import {
    db,
    doc,
    updateDoc,
    getDoc,
    auth
} from "../firebase";
import { useAuthState } from 'react-firebase-hooks/auth';
import Navbar from "../Navbar";
import NavbarSide from "../NavbarSide";

export default function Messages() {

    useEffect(() => {
        document.title = "Settings"; 
    }, []);

    const route = useNavigate();
    const [user] = useAuthState(auth);
    const [active, setActive] = useState(Boolean);
    const [publicMailbox, setPublic] = useState(Boolean);
    const [ userInfo, setUserInfo ] = useState([]);

    const fetchUser = async () => {
        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setUserInfo([docSnap.data()]);

            if(docSnap.data().isActive == null){
                setActive(true);
            } else {
                setActive(docSnap.data().isActive);
            }

            if(docSnap.data().isPublic == null){
                setPublic(true);
            } else {
                setPublic(docSnap.data().isPublic);
            }
            
        } else {
            console.log("No such document!");
        }
    }
    useEffect(() => {
        fetchUser();
    }, [])

    function changeActive(value) {

        if(value === "true"){
            setActive(true)
        } else {
            setActive(false)
        }
    }

    function changePublic(value) {

        if(value === "true"){
            setPublic(true)
        } else {
            setPublic(false)
        }
    }

    //////////////////////
    //Update User Info
    /////////////////////
    const handleSubmit = (event) => {
        event.preventDefault();
        updateMailbox();
    }
    async function updateMailbox() {
        const mailboxUserStatus = doc(db, "users", auth.currentUser.uid);
        await updateDoc(mailboxUserStatus, {
            isActive: active,
            isPublic: publicMailbox
        });
        const mailboxProfileStatus = doc(db, "profiles", auth.currentUser.uid);
        await updateDoc(mailboxProfileStatus, {
            isActive: active,
            isPublic: publicMailbox
        });
        alert("Update complete");
        route("/settings")
    }

    return (
        <div>
        <div className="d-md-none d-block">
            <Navbar/>
        </div>  

        <div className="row">

            <NavbarSide/>     
                <div className='ps-3 pe-4 col'>

        
                    <div className="row justify-content-start p-0">

                        <form onSubmit={handleSubmit} >
                            <div className='row g-3 text-start'>
                                <h2 className="text-center mb-0">Mailbox Settings</h2>

                                <div className="row m-0 p-0 mt-5">
                                    <div className="col-5 pt-2">
                                        Public Mailbox:   
                                    </div>
                                    <div className="col-7">
                                        <select id="active" className="form-select py-2" value={publicMailbox} onChange={(e) => changePublic(e.target.value)} required>
                                            <option value={true}>Yes</option>
                                            <option value={false}>No</option>
                                        </select>
                                    </div>
                                    <div className="col-12 pt-2">
                                        <small>A public mailbox allows anyone to leave you a message. If you want to restrict you mailbox to only Housephone users, select no.</small>
                                    </div>
                                </div>
                                
                                <div className="row m-0 p-0 mt-5">
                                    <div className="col-5 pt-2">
                                        Mailbox Active:   
                                    </div>
                                    <div className="col-7">
                                        <select id="active" className="form-select py-2" value={active} onChange={(e) => changeActive(e.target.value)} required>
                                            <option value={true}>True</option>
                                            <option value={false}>False</option>
                                        </select>
                                    </div>
                                    <div className="col-12 pt-2">
                                        <small>When set to false users will no longer be able to leave you a message and your profile will show "Inbox Full"</small>
                                    </div>
                                </div>

                                <div className="row m-0 p-0 mt-5">
                                    <div className="col-5 pt-2">
                                        Message Length:
                                    </div>
                                    <div className="col-7">
                                        <select id="length" className="form-select py-2" required>
                                        <option defaultValue="30">30 Seconds</option>
                                        <option value="40" disabled>45 Seconds (Pro - coming soon)</option>
                                        <option value="60" disabled>1 minute (Pro - coming soon)</option>
                                        <option value="120" disabled>2 minutes (Pro - coming soon)</option>
                                        <option value="180" disabled>3 minutes (Pro - coming soon)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row m-0 p-0 mt-3">
                                    <div className="col-5 pt-2">
                                        Mailbox Size:
                                    </div>
                                    <div className="col-7">
                                        <select id="length" className="form-select py-2" required>
                                        <option default value="20">20 Voicemails</option>
                                        <option value="150" disabled>150 Voicemails (Pro - coming soon)</option>
                                        <option value="300" disabled>300 Voicemails (Pro - coming soon)</option>
                                        </select>
                                    </div>
                                </div>

                                {/*
                                <div className="row m-0 p-0 mt-5">
                                    <div className="col-12 pt-2">
                                        Voicemail Frequency:
                                    </div>
                                    <div className="col-12" style={{fontSize: `0.8em`}}>
                                        How often someone can send you a Voicemail?
                                    </div>
                                    <div className="col-5 mb-1">
                                        <select id="length" className="form-select text-secondary py-2" required>
                                        <option defaultValue="1">Once</option>
                                        <option value="2">Twice</option>
                                        <option value="3" disabled>3 times (pro)</option>
                                        <option value="4" disabled>4 times (pro)</option>
                                        <option value="5" disabled>5 times (pro)</option>
                                        <option value="6" disabled>6 times (pro)</option>
                                        <option value="7" disabled>7 times (pro)</option>
                                        <option value="unlimited" disabled>Unlimited (pro)</option>
                                        </select>
                                    </div>
                                    <div className="col-2 pt-2 text-center">
                                        per
                                    </div>
                                    <div className="col-5 mb-1">
                                        <select id="length" className="form-select text-secondary py-2" required>
                                        <option defaultValue="day" >day</option>
                                        <option value="week" disabled>week (pro)</option>
                                        <option value="month" disabled>month (pro)</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row m-0 p-0 mt-5">
                                    <div className="col-12">
                                        Custom Mailboxes:
                                    </div>
                                    <div style={{fontSize: `0.8em`}}>
                                        Pro accounts have the option to add custom mailboxes for things like; episode feedback, customer support, etc
                                    </div>
                                    <div className="col-6 mb-1">
                                        <select id="length" className="form-select text-secondary py-2">
                                            <option defaultValue="1">Main Mailbox</option>
                                        </select>
                                    </div>
                                    <div className="col-6 mb-1">
                                        <select id="length" className="form-select text-secondary py-2">
                                            <option defaultValue="1">Notify Via</option>
                                            <option defaultValue="app">App</option>
                                            <option defaultValue="email">Email</option>
                                            <option defaultValue="text">Text</option>
                                            <option defaultValue="all">All</option>
                                        </select>
                                    </div>
                                    <div className="col-6 mb-1">
                                        <select id="length" className="form-select text-secondary py-2">
                                            <option defaultValue="1">Second Mailbox</option>
                                        </select>
                                    </div>
                                    <div className="col-6 mb-1">
                                        <select id="length" className="form-select text-secondary py-2">
                                            <option defaultValue="1">Notify me via</option>
                                            <option defaultValue="app">App</option>
                                            <option defaultValue="email">Email</option>
                                            <option defaultValue="text">Text</option>
                                            <option defaultValue="all">All</option>
                                        </select>
                                    </div>
                                </div>
                                */}
                                <div className="col-12">
                                    <button type="submit" className="btn w-100 btn-dark mt-3 p-3">Update</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
