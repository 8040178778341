import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    db,
    doc,
    updateDoc,
    getDoc,
    auth
} from "../firebase";
import { useAuthState } from 'react-firebase-hooks/auth';
import 'react-phone-number-input/style.css' //figure out padding and border
import Navbar from "../Navbar";
import NavbarSide from "../NavbarSide";

export default function BasicInfo() {

    useEffect(() => {
        document.title = "Settings"; 
    }, []);

    const route = useNavigate();
    const [user] = useAuthState(auth);
    const [userInfo, setUserInfo] = useState([]);
    const [displayName, setDisplay] = useState();
    const [bio, setBio] = useState();
    const [phone, setPhone] = useState();
    const [month, setMonth] = useState();
    const [day, setDay] = useState();
    const [year, setYear] = useState();
    const [country, setCountry] = useState(); 
    const [region, setRegion] = useState(); 

    function changePhone(value) {
        setPhone(value);
    }
    function changeDisplay(value) {
        setDisplay(value);
    }
    function changeBio(value) {
        setBio(value);
    }
    function changeMonth(value) {
        setMonth(value);
    }
    function changeDay(value) {
        setDay(value);
    }
    function changeYear(value) {
        setYear(value);
    }

    const fetchUser = async () => {
        const docRef = doc(db, "users", auth.currentUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            setUserInfo([docSnap.data()]);
            setDisplay(docSnap.data().displayName);
            setBio(docSnap.data().bio);
            setPhone(docSnap.data().phone ? docSnap.data().phone : " ");
            setMonth(docSnap.data().birthday ? docSnap.data().birthday['month'] : " ");
            setDay(docSnap.data().birthday ? docSnap.data().birthday['day'] : " ");
            setYear(docSnap.data().birthday ? docSnap.data().birthday['year'] : " ");
        } else {
            console.log("No such document!");
        }
    }
    useEffect(() => {
        fetchUser();
    }, [])

    //////////////////////
    //Update User Info
    /////////////////////
    const handleSubmit = (event) => {
        event.preventDefault();
        updateInfo()
    }
    async function updateInfo(){
        const userInfoRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(userInfoRef, {
            displayName: displayName,
            phone: phone,
            bio: bio,
            birthday: {month: month, day: day, year: year}
        });
        const profileInfoRef = doc(db, "profiles", auth.currentUser.uid);
        await updateDoc(profileInfoRef, {
            displayName: displayName,
            bio: bio
        });
        alert("Update complete");
        route("/settings")
    }

    return (
        <div>
        <div className="d-md-none d-block">
            <Navbar/>
        </div>  

        <div className="row">

            <NavbarSide/>     
                <div className='ps-3 pe-4 col'>

        
                    <div className="row justify-content-start p-0">
                        
                    { userInfo && userInfo.map(userData => 

                        
                        <form onSubmit={handleSubmit} key={userData.id + "-form"}>
                            <div className='row g-3 text-start'>
                            <h3 className="text-center">Basic Info</h3>
                                <div className="col-12 mb-2">
                                    <strong>Name</strong>
                                    <p style={{fontSize: `0.6em`, margin: `0px`}}>This is how people find you!</p>  
                                    <input type="text" className='form-control p-2 w-100' name="displayName" defaultValue={userData.displayName} onChange={(e) => changeDisplay(e.target.value)} required />
                                </div>
                                <div className="col-12 mb-2">
                                    <strong>Bio</strong>
                                    <input type="text" className='form-control p-2 w-100' name="bio" defaultValue={userData.bio} onChange={(e) => changeBio(e.target.value)} />
                                </div>
                                <div className="col-12 mb-2">
                                    <strong>Phone Number</strong>
                                    <input type="text" className='form-control p-2 w-100' defaultValue={userData.phone} onChange={(e) => changePhone(e.target.value)} maxLength={14}/>
                                </div>
                                <div className="col-12 mb-2">
                                    <strong>Birthday</strong>
                                    <div className="row mb-1 g-1">
                                    <div className="col-5 mb-0">
                                        <select defaultValue={userData.birthday ? userData.birthday['month'] : " "} onChange={(e) => changeMonth(e.target.value)}  id="month" className="form-select text-secondary py-2">
                                        <option value="">Month</option>
                                        <option value="1">January</option>
                                        <option value="2">Feburary</option>
                                        <option value="3">March</option>
                                        <option value="4">April</option>
                                        <option value="5">May</option>
                                        <option value="6">June</option>
                                        <option value="7">July</option>
                                        <option value="8">August</option>
                                        <option value="9">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                        </select>
                                    </div>
                                    <div className="col-3 mb-0">
                                        <select defaultValue={userData.birthday ? userData.birthday['day'] : " "} onChange={(e) => changeDay(e.target.value)} id="day" className="form-select text-secondary py-2">
                                        <option value="">Day</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                        </select>
                                    </div>
                                    <div className="col-4 mb-0">
                                        <select defaultValue={userData.birthday ? userData.birthday['year'] : " "} onChange={(e) => changeYear(e.target.value)}  id="year" className="form-select text-secondary py-2">
                                        <option value="">Year</option>
                                        <option value="2010">2010</option>
                                        <option value="2009">2009</option>
                                        <option value="2008">2008</option>
                                        <option value="2007">2007</option>
                                        <option value="2006">2006</option>
                                        <option value="2005">2005</option>
                                        <option value="2004">2004</option>
                                        <option value="2003">2003</option>
                                        <option value="2002">2002</option>
                                        <option value="2001">2001</option>
                                        <option value="2000">2000</option>
                                        <option value="1999">1999</option>
                                        <option value="1998">1998</option>
                                        <option value="1997">1997</option>
                                        <option value="1996">1996</option>
                                        <option value="1995">1995</option>
                                        <option value="1994">1994</option>
                                        <option value="1993">1993</option>
                                        <option value="1992">1992</option>
                                        <option value="1991">1991</option>
                                        <option value="1990">1990</option>
                                        <option value="1989">1989</option>
                                        <option value="1988">1988</option>
                                        <option value="1987">1987</option>
                                        <option value="1986">1986</option>
                                        <option value="1985">1985</option>
                                        <option value="1984">1984</option>
                                        <option value="1983">1983</option>
                                        <option value="1982">1982</option>
                                        <option value="1981">1981</option>
                                        <option value="1980">1980</option>
                                        <option value="1979">1979</option>
                                        <option value="1978">1978</option>
                                        <option value="1977">1977</option>
                                        <option value="1976">1976</option>
                                        <option value="1975">1975</option>
                                        <option value="1974">1974</option>
                                        <option value="1973">1973</option>
                                        <option value="1972">1972</option>
                                        <option value="1971">1971</option>
                                        <option value="1970">1970</option>
                                        <option value="1969">1969</option>
                                        <option value="1968">1968</option>
                                        <option value="1967">1967</option>
                                        <option value="1966">1966</option>
                                        <option value="1965">1965</option>
                                        <option value="1964">1964</option>
                                        <option value="1963">1963</option>
                                        <option value="1962">1962</option>
                                        <option value="1961">1961</option>
                                        <option value="1960">1960</option>
                                        <option value="1959">1959</option>
                                        <option value="1958">1958</option>
                                        <option value="1957">1957</option>
                                        <option value="1956">1956</option>
                                        <option value="1955">1955</option>
                                        <option value="1954">1954</option>
                                        <option value="1953">1953</option>
                                        <option value="1952">1952</option>
                                        <option value="1951">1951</option>
                                        <option value="1950">1950</option>
                                        <option value="1949">1949</option>
                                        <option value="1948">1948</option>
                                        <option value="1947">1947</option>
                                        <option value="1946">1946</option>
                                        <option value="1945">1945</option>
                                        <option value="1944">1944</option>
                                        <option value="1943">1943</option>
                                        <option value="1942">1942</option>
                                        <option value="1941">1941</option>
                                        <option value="1940">1940</option>
                                        <option value="1939">1939</option>
                                        <option value="1938">1938</option>
                                        <option value="1937">1937</option>
                                        <option value="1936">1936</option>
                                        <option value="1935">1935</option>
                                        <option value="1934">1934</option>
                                        <option value="1933">1933</option>
                                        <option value="1932">1932</option>
                                        <option value="1931">1931</option>
                                        <option value="1930">1930</option>
                                        <option value="1929">1929</option>
                                        <option value="1928">1928</option>
                                        <option value="1927">1927</option>
                                        <option value="1926">1926</option>
                                        <option value="1925">1925</option>
                                        <option value="1924">1924</option>
                                        <option value="1923">1923</option>
                                        <option value="1922">1922</option>
                                        <option value="1921">1921</option>
                                        <option value="1920">1920</option>
                                        <option value="1919">1919</option>
                                        <option value="1918">1918</option>
                                        <option value="1917">1917</option>
                                        <option value="1916">1916</option>
                                        <option value="1915">1915</option>
                                        </select>
                                    </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn w-100 btn-dark mt-3 p-3">Update</button>
                                </div>
                            </div>
                        </form>
                        
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
